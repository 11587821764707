import { createAction, createReducer } from "redux-act";
import { reactLocalStorage } from "reactjs-localstorage";
import authLocalStorage from "./../utils/auth";

const initialState = {
  auth: false,
  accessToken: null,
  user: {},
  lang: reactLocalStorage.get("lang", "es"),
};

export const fetchAuth = createAction(
  "fetch auth",
  ({ auth, accessToken }) => ({
    auth,
    accessToken,
  })
);
export const fetchUser = createAction("fetch user", (user) => ({ user }));

export const changeLang = createAction("change language", (lang) => ({ lang }));

export const changeUserProps = createAction(
  "change user props",
  (newProps) => ({ newProps })
);

export default createReducer(
  {
    [fetchAuth]: (state, { auth, accessToken }) =>
      Object.assign({}, state, {
        auth,
        accessToken,
      }),
    [fetchUser]: (state, { user }) => Object.assign({}, state, { user }),
    [changeLang]: (state, { lang }) => {
      reactLocalStorage.set("lang", lang);
      return Object.assign({}, state, { lang });
    },
    [changeUserProps]: (state, { newProps }) => {
      let oldUserInfo = authLocalStorage.getUserInfo();
      oldUserInfo = Object.assign(oldUserInfo, newProps);
      authLocalStorage.setUserInfo(oldUserInfo, true);

      const newUserState = Object.assign(state.user, newProps);
      return Object.assign({}, state, { user: newUserState });
    },
  },
  initialState
);
