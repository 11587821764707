import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import intlEN from "react-intl/locale-data/en";
import intlES from "react-intl/locale-data/es";
import intlJA from "react-intl/locale-data/ja";
import intlPT from "react-intl/locale-data/pt";

import messages_en from "./../../translations/en.json";
import messages_es from "./../../translations/es.json";
import messages_ja from "./../../translations/ja.json";
import messages_pt from "./../../translations/pt.json";

import { changeLang } from "./../../reducers/auth";

addLocaleData([...intlEN, ...intlES, ...intlJA, ...intlPT]);
const messages = {
  en: messages_en,
  es: messages_es,
  ja: messages_ja,
  pt: messages_pt,
};

const IntlWrapper = (props) => {
  const { children, lang, location, authChangeLang } = props;

  let langParam = "";
  langParam += location.pathname[1];
  langParam += location.pathname[2];
  if (
    langParam === "en" ||
    langParam === "es" ||
    langParam === "ja" ||
    langParam === "pt"
  ) {
    authChangeLang(langParam);
  } else {
    authChangeLang(lang);
  }

  return (
    <IntlProvider
      key={lang}
      locale={lang}
      messages={messages[lang]}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.rootReducer.auth.lang,
    location: state.router.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authChangeLang: bindActionCreators(changeLang, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlWrapper);
