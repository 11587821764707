import { combineReducers } from "redux";
import process from "./process";
import auth from "./auth";
import notification from "./notification";

const rootReducer = combineReducers({
  process,
  auth,
  notification,
});

export default rootReducer;
