import React from 'react'
import { Spin, Row } from 'antd'

const Loader = () => (
	<Row justify="center" type="flex" style={{ marginTop: 60 }}>
		<Spin size="large" />
	</Row>
)

export default Loader
