import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import IntlWrapper from "components/IntlWrapper";
import ScrollToTop from "components/AppComponents/ScrollToTop";
import App from "./App";

class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ConfigProvider locale={enUS}>
            <IntlWrapper>
              <ScrollToTop>
                <Route path="/" component={App} />
              </ScrollToTop>
            </IntlWrapper>
          </ConfigProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
