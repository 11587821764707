import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import loadable from "react-loadable";
import LoadingComponent from "components/AppComponents/Loading";
import PrivateRoute from "./PrivateRoute";

import "antd/dist/antd.css";

let AsyncNotFound = loadable({
  loader: () => import("components/NotFound"),
  loading: LoadingComponent,
});
let AsyncDashboard = loadable({
  loader: () => import("containers/Dashboard"),
  loading: LoadingComponent,
});
let AsyncMain = loadable({
  loader: () => import("containers/Main"),
  loading: LoadingComponent,
});
let AsyncMasker = loadable({
  loader: () => import("containers/Masker"),
  loading: LoadingComponent,
});
let AsyncUkraine = loadable({
  loader: () => import("containers/Ukraine"),
  loading: LoadingComponent,
});

const AppTheme = styled.div``;

class App extends React.Component {
  render() {
    const { location } = this.props;

    if (
      window.location.hostname.includes("t4ukraine.pangeanic.com") &&
      location.pathname.toLowerCase() !== "/trans4ukraine"
    ) {
      return <Redirect to={"/trans4ukraine"} />;
    }

    const isRoot = location.pathname === "/" ? true : false;
    if (isRoot) {
      return <Redirect to={"/main"} />;
    }

    return (
      <ThemeProvider theme={{ theme: "pangeanic" }}>
        <AppTheme id="app">
          <Switch>
            <Route path="/main" component={AsyncMain} />
            <Route path="/trial" component={AsyncMain} />
            <Route path="/freetrial" component={AsyncMain} />
            <Route path="/masker" component={AsyncMasker} />
            <Route path="/trans4ukraine" component={AsyncUkraine} />
            <Route path="/mapa" component={AsyncMasker} />
            <PrivateRoute path="/dashboard" component={AsyncDashboard} />
            <Route exact path="/404" component={AsyncNotFound} />

            <Route render={() => <Redirect to="/404" />} />
          </Switch>
        </AppTheme>
      </ThemeProvider>
    );
  }
}

export default App;
