import { createAction, createReducer } from "redux-act";
const array = require("lodash/array");

const initialState = {
  notifications: [],
};

export const fetchAuth = createAction(
  "fetch auth",
  ({ auth, accessToken }) => ({
    auth,
    accessToken,
  })
);
export const addNotifications = createAction(
  "add  notifications",
  (notifications) => ({
    notifications,
  })
);
export const changeNotifications = createAction(
  "change  notifications",
  (notifications) => ({
    notifications,
  })
);

export default createReducer(
  {
    [changeNotifications]: (state, { notifications }) =>
      Object.assign({}, state, { notifications }),
    [addNotifications]: (state, { notifications }) => {
      notifications = array.concat(state.notifications, notifications);

      return {
        ...state,
        notifications,
      };
    },
  },
  initialState
);
