import { createStore, compose, combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import rootReducer from "./../reducers";

export default function configureStore(initialState, history) {
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false,
        })
      : compose;

  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      rootReducer,
      initialState,
    }),

    composeEnhancers()
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./../reducers", () => {
      const nextReducer = require("./../reducers").default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
