import { createAction, createReducer } from "redux-act";

const initialState = {
  selected: {
    name: null,
    engines: [],
  },
  loading: false,
  engines: [],
  defaultValue: null,
  fileId: null,
  processSelected: null,
};

export const fetchRequest = createAction("fetch request", (loading) => ({
  loading,
}));
export const fetchResponse = createAction("fetch response", (loading) => ({
  loading,
}));
export const getEngines = createAction(
  "get engines",
  (engines, defaultValue) => {
    return {
      engines,
      defaultValue,
    };
  }
);
export const selectProcess = createAction("select process", (selected) => ({
  selected,
}));
export const setProcessSelected = createAction(
  "set processSelected",
  (processSelected) => ({
    processSelected,
  })
);
export const changeFileID = createAction("change fileId", (fileId) => ({
  fileId,
}));

export default createReducer(
  {
    [setProcessSelected]: (state, { processSelected }) =>
      Object.assign({}, state, { processSelected }),
    [selectProcess]: (state, { selected }) =>
      Object.assign({}, state, { selected }),
    [getEngines]: (state, { engines, defaultValue }) =>
      Object.assign({}, state, { engines, defaultValue }),
    [changeFileID]: (state, { fileId }) => Object.assign({}, state, { fileId }),
    [fetchRequest]: (state) => Object.assign({}, state, { loading: true }),
    [fetchResponse]: (state) => Object.assign({}, state, { loading: false }),
  },
  initialState
);
